import React from "react"

import Hero from "../../Layout/Hero"
import Container from "../../Layout/Container"
import CardButtons from "../../Elements/CardButtons"
import { navigate } from "gatsby"

const Home = () => {
  const buttons = [
    {
      sku: "respimat",
      label:
        "Tiotropium Bromide + Olodaterol HCl (Spiolto<sup>®</sup> Respimat<sup>®</sup>)",
      img: "inhaler",
    },
    {
      sku: "jardiance",
      label:
        "Empagliflozin (Jardiance<sup>®</sup>) 10mg for heart failure reduced ejection fraction",
      img: "pills",
    },
  ]

  return (
    <Hero
      color="light"
      size="fullheight"
      // backgroundImage={data.heart.childImageSharp.fluid.src}
    >
      <Container isCentered desktop={10} fullhd={9}>
        {console.log("HERE")}
        {/* <figure className={styles["logo"]}>
        <Img fluid={medgrocer} alt="MedGrocer" />
      </figure> */}
        <h1 className="title is-size-1-desktop is-size-3-mobile has-text-primary mt-1 mb-4">
          The PatientLink program with MedGrocer has now ended.
        </h1>
        <h3 className="subtitle is-size-3-desktop is-size-5-mobile">
          For inquiries on your PatientLink medicines, please reach out to your
          prescribing doctor. Thank you for letting us serve you.
        </h3>
      </Container>
    </Hero>
    // <Container isCentered desktop={10} fullhd={8}>
    //   <div className="container has-text-centered fade-to-right">
    //     <h2>Welcome to the PatientLink Sampling Program</h2>
    //     <h4 className="subtitle">What medicine were you prescribed with?</h4>
    //     <CardButtons buttons={buttons} />
    //   </div>
    // </Container>
  )
}

export default Home
